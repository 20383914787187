.fixedMenuBar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100; /* Ensure it sits on top of other content */
  }

  .logo {
    animation: logoAnimation 2s forwards;
    animation-delay: 250ms;
  }

  @keyframes logoAnimation {
    0% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(3);
    }
    50% {
      top: 1rem;
      left: 50%;
      transform: translate(-50%, 0) scale(3);
    }
    100% {
      transform: scale(1);
      top: 1rem;
      left: 1rem;
    }
  }

  .hero-section {
    transform: translateY(100vh);
    transition: transform 2s;
  }

  .hero-section.visible {
    transform: translateY(0);
  }